import { createId } from '@paralleldrive/cuid2'
import { text, timestamp } from 'drizzle-orm/pg-core'
import { alphabet, generateRandomString } from 'oslo/crypto'

export const id = {
  id: text('id').$defaultFn(createId).primaryKey(),
}

export const timestamps = {
  createdAt: timestamp('created_at').defaultNow().notNull(),
  updatedAt: timestamp('updated_at').defaultNow().notNull(),
  // .$onUpdate(() => new Date()),
}

const idAlphabet = alphabet('a-z', '0-9')

export const publicId = {
  publicId: text('public_id')
    .$defaultFn(() => generateRandomString(12, idAlphabet))
    .notNull(),
}
