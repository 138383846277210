import { Icons } from '@/components/icons'
import { StatusButton } from '@/components/ui/status-button'
import { useIsPending } from '@/lib/utils'
import { oauthProviders } from '@fuse/core/db/schema'
import { Form } from '@remix-run/react'
import { z } from 'zod'

export const providerNameSchema = z.enum(oauthProviders)
export type ProviderName = z.infer<typeof providerNameSchema>

export const providerLabels: Record<ProviderName, string> = {
  google: 'Google',
  // apple: 'Apple',
} as const

export const providerIcons: Record<ProviderName, React.ReactNode> = {
  google: <Icons.Google />,
  // apple: <Icons.Apple />,
} as const

export function ProviderConnectionForm({
  redirectTo,
  authAction,
  providerName,
}: {
  redirectTo?: string | null
  authAction: 'login' | 'sign-up'
  providerName: ProviderName
}) {
  const label = providerLabels[providerName]
  const formAction = `/oauth/${providerName}/${authAction}`
  const isPending = useIsPending({ formAction })
  return (
    <Form
      className='flex items-center justify-center gap-2'
      action={formAction}
      method='POST'
    >
      {redirectTo ? (
        <input type='hidden' name='redirectTo' value={redirectTo} />
      ) : null}

      <StatusButton
        type='submit'
        className='w-full'
        status={isPending ? 'pending' : 'idle'}
      >
        <span className='inline-flex font-semibold items-center gap-1.5'>
          {providerIcons[providerName]}
          <span className='mt-0.5'>Continue with {label}</span>
        </span>
      </StatusButton>
    </Form>
  )
}
