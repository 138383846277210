import {
  type InferInsertModel,
  type InferSelectModel,
  relations,
} from 'drizzle-orm'
import {
  bigint,
  boolean,
  index,
  integer,
  jsonb,
  pgEnum,
  pgTable,
  primaryKey,
  serial,
  text,
  timestamp,
  uniqueIndex,
} from 'drizzle-orm/pg-core'
import type { Metadata as BlobMetadata } from '../storage/blob'
import { id, publicId, timestamps } from './sql'

export const user = pgTable(
  'user',
  {
    ...id,
    ...timestamps,
    email: text('email').notNull(),
    emailVerified: timestamp('email_verified', { mode: 'date' }),
    firstName: text('first_name'),
    lastName: text('last_name'),
    username: text('username').notNull(),
    marketingContact: boolean('marketing_contact').notNull().default(false),

    pageExpirationEmails: boolean('page_expiration_emails')
      .notNull()
      .default(true),
    fileDownloadEmails: boolean('file_download_emails').notNull().default(true),

    pageVisitEmails: boolean('page_visit_emails').notNull().default(true),
    visitEmailSentAt: timestamp('visit_email_sent_at'),

    lastSeenReleaseId: text('last_seen_release_id').references(
      () => release.id,
      { onDelete: 'set null' },
    ),
  },
  (user) => ({
    emailIdx: uniqueIndex().on(user.email),
    usernameIdx: uniqueIndex().on(user.username),
  }),
)

export const userRelations = relations(user, ({ one, many }) => ({
  sessions: many(session),
  oauthAccounts: many(oauthAccount),
  pages: many(page),
  workspaceMemberships: many(workspaceUser),
  image: one(attachment, {
    fields: [user.id],
    references: [attachment.recordId],
  }),
}))

export type User = InferSelectModel<typeof user>
export type NewUser = InferInsertModel<typeof user>

export const session = pgTable('session', {
  id: text('id').primaryKey(),
  userId: text('user_id')
    .notNull()
    .references(() => user.id, { onDelete: 'cascade' }),
  expiresAt: timestamp('expires_at', {
    mode: 'date',
  }).notNull(),
})

export const sessionRelations = relations(session, ({ one }) => ({
  user: one(user, {
    fields: [session.userId],
    references: [user.id],
  }),
}))

export type Session = InferSelectModel<typeof session>
export type NewSession = InferInsertModel<typeof session>

export const otp = pgTable('otp', {
  ...id,
  createdAt: timestamp('created_at', { mode: 'date' }).defaultNow().notNull(),
  expiresAt: timestamp('expires_at', { mode: 'date' }).notNull(),
  challenge: text('challenge').notNull(),
  email: text('email').notNull(),
  hashedOtp: text('hashed_otp').notNull(),
})

export type Otp = InferSelectModel<typeof otp>
export type NewOtp = InferInsertModel<typeof otp>

export const oauthProviders = ['google'] as const

export const oauthAccount = pgTable(
  'oauth_account',
  {
    providerId: text('provider_id', { enum: oauthProviders }).notNull(),
    providerUserId: text('provider_user_id').notNull(),
    userId: text('user_id')
      .notNull()
      .references(() => user.id, { onDelete: 'cascade' }),
    ...timestamps,
  },
  (oauthAccount) => ({
    pk: primaryKey({
      columns: [oauthAccount.providerId, oauthAccount.providerUserId],
    }),
  }),
)

export const oauthAccountRelations = relations(oauthAccount, ({ one }) => ({
  user: one(user, {
    fields: [oauthAccount.userId],
    references: [user.id],
  }),
}))

export type OauthAccount = InferSelectModel<typeof oauthAccount>
export type NewOauthAccount = InferInsertModel<typeof oauthAccount>

export const subscriptionTierEnum = pgEnum('subscription_tier', [
  'free',
  'creator',
  'pro',
])

export const workspace = pgTable('workspace', {
  ...id,
  ...timestamps,
  name: text('name').notNull(),
  slug: text('slug').notNull().unique(),
  tier: subscriptionTierEnum('tier').default('free').notNull(),
  storageLimit: integer('storage_limit').default(3),
  instagram: text('instagram'),
  spotify: text('spotify'),
  tiktok: text('tiktok'),
  website: text('website'),
})

export type Workspace = InferSelectModel<typeof workspace>
export type NewWorkspace = InferInsertModel<typeof workspace>

export const workspaceUser = pgTable(
  'workspace_user',
  {
    workspaceId: text('workspace_id')
      .notNull()
      .references(() => workspace.id),
    userId: text('user_id')
      .notNull()
      .references(() => user.id),
    ...timestamps,
  },
  (workspaceUser) => ({
    pk: primaryKey({
      columns: [workspaceUser.workspaceId, workspaceUser.userId],
    }),
    workspaceIdx: index().on(workspaceUser.workspaceId),
  }),
)

export const workspaceUserRelations = relations(workspaceUser, ({ one }) => ({
  user: one(user, {
    fields: [workspaceUser.userId],
    references: [user.id],
  }),
  workspace: one(workspace, {
    fields: [workspaceUser.workspaceId],
    references: [workspace.id],
  }),
}))

export type WorkspaceUser = InferSelectModel<typeof workspaceUser>
export type NewWorkspaceUser = InferInsertModel<typeof workspaceUser>

export const blob = pgTable(
  'blob',
  {
    ...id,
    ...timestamps,
    workspaceId: text('workspace_id')
      .notNull()
      .references(() => workspace.id),
    userId: text('user_id')
      .notNull()
      .references(() => user.id),
    name: text('name').notNull(),
    type: text('type').notNull(),
    size: bigint('size', { mode: 'number' }).notNull(),
    identified: boolean('identified').notNull().default(false),
    checksum: text('checksum').notNull(),
    metadata: jsonb('metadata').$type<BlobMetadata | null>(),
    contentHash: text('content_hash'),
    txHash: text('tx_hash'),
    txMinedAt: timestamp('tx_mined_at'),
  },
  (blob) => ({
    workspaceIdx: index().on(blob.workspaceId),
  }),
)

export type Blob = InferSelectModel<typeof blob>
export type NewBlob = InferInsertModel<typeof blob>

export const attachment = pgTable(
  'attachment',
  {
    ...id,
    recordType: text('record_type').notNull(),
    recordId: text('record_id').notNull(),
    name: text('name').notNull(),
    blobId: text('blob_id')
      .notNull()
      .references(() => blob.id),
  },
  (attachment) => ({
    uniqueAttachment: uniqueIndex().on(
      attachment.recordType,
      attachment.recordId,
      attachment.name,
      attachment.blobId,
    ),
  }),
)

export const attachmentRelations = relations(attachment, ({ one }) => ({
  blob: one(blob, {
    fields: [attachment.blobId],
    references: [blob.id],
  }),
  pageFile: one(pageFile, {
    fields: [attachment.recordId],
    references: [pageFile.id],
  }),
  user: one(user, {
    fields: [attachment.recordId],
    references: [user.id],
  }),
}))

export type Attachment = InferSelectModel<typeof attachment>
export type NewAttachment = InferInsertModel<typeof attachment>

export const pageStatusEnum = pgEnum('page_status', ['draft', 'published'])

export const page = pgTable(
  'page',
  {
    ...id,
    ...timestamps,
    workspaceId: text('workspace_id')
      .notNull()
      .references(() => workspace.id),
    userId: text('user_id')
      .notNull()
      .references(() => user.id),

    /**
     * used in public URLs of the page
     *
     * ex: /lukerucker/page-name-01m234mns3
     */
    ...publicId,

    name: text('name').default('Unnamed page').notNull(),
    nameSlug: text('name_slug').default('unnamed-page').notNull(),
    disableDownloads: boolean('disable_downloads').notNull().default(false),
    showSocials: boolean('show_socials').notNull().default(true),
    description: text('description'),
    note: text('note'),

    /**
     * a page is considered a draft if these are both null.
     * when a page is published, these get set.
     * a page is considered expired when expires_at is in the past
     */
    publishedAt: timestamp('published_at', { mode: 'date' }),
    expiresAt: timestamp('expires_at', { mode: 'date' }),
  },
  (page) => ({
    workspace: index().on(page.workspaceId),
    publicId: uniqueIndex().on(page.publicId),
  }),
)

export const pageVisit = pgTable(
  'page_visit',
  {
    ...id,
    ...timestamps,
    pageId: text('page_id')
      .notNull()
      .references(() => page.id, { onDelete: 'cascade' }),
  },
  (visit) => ({
    pageIndex: index().on(visit.pageId),
    visitedAtIndex: index().on(visit.createdAt),
    userNotifiedPageCreatedAtIndex: index().on(visit.pageId, visit.createdAt),
  }),
)

export const fileDownload = pgTable('file_download', {
  ...id,
  ...timestamps,
  pageFileId: text('page_file_id')
    .notNull()
    .references(() => pageFile.id, { onDelete: 'set null' }),
  userFileId: text('user_file_id').references(() => userFile.id, {
    onDelete: 'cascade',
  }),
})

export const pageVisitRelations = relations(pageVisit, ({ one }) => ({
  page: one(page, {
    fields: [pageVisit.pageId],
    references: [page.id],
  }),
}))

export const fileDownloadRelations = relations(fileDownload, ({ one }) => ({
  pageFile: one(pageFile, {
    fields: [fileDownload.pageFileId],
    references: [pageFile.id],
  }),
  userFile: one(userFile, {
    fields: [fileDownload.userFileId],
    references: [userFile.id],
  }),
}))

export const pageRelations = relations(page, ({ one, many }) => ({
  user: one(user, { fields: [page.userId], references: [user.id] }),
  workspace: one(workspace, {
    fields: [page.workspaceId],
    references: [workspace.id],
  }),
  pageFiles: many(pageFile),
  pageVisits: many(pageVisit),
  coverImage: one(attachment, {
    fields: [page.id],
    references: [attachment.recordId],
  }),
}))

export type Page = InferSelectModel<typeof page>
export type NewPage = InferInsertModel<typeof page>

export const pageFile = pgTable(
  'page_file',
  {
    ...id,
    ...timestamps,
    pageId: text('page_id')
      .notNull()
      .references(() => page.id),
  },
  (pageFile) => ({
    pageIdx: index().on(pageFile.pageId),
  }),
)

export const pageFileRelations = relations(pageFile, ({ one, many }) => ({
  page: one(page, {
    fields: [pageFile.pageId],
    references: [page.id],
  }),
  file: one(attachment, {
    fields: [pageFile.id],
    references: [attachment.recordId],
  }),
  fileDownloads: many(fileDownload),
}))

export const userFile = pgTable(
  'user_file',
  {
    ...id,
    ...timestamps,
    userId: text('user_id')
      .notNull()
      .references(() => user.id),
  },
  (userFile) => ({
    userIdx: index().on(userFile.userId),
  }),
)

export const userFileRelations = relations(userFile, ({ one, many }) => ({
  user: one(user, {
    fields: [userFile.userId],
    references: [user.id],
  }),
  file: one(attachment, {
    fields: [userFile.id],
    references: [attachment.recordId],
  }),
  fileDownloads: many(fileDownload),
}))

export type PageFile = InferSelectModel<typeof pageFile>
export type NewPageFile = InferInsertModel<typeof pageFile>

export const lock = pgTable('lock', {
  key: text('key').primaryKey(),
  owner: text('owner').notNull(),
  expiration: bigint('expiration', { mode: 'number' }),
})

export type Lock = InferSelectModel<typeof lock>
export type NewLock = InferInsertModel<typeof lock>

export const release = pgTable('release', {
  ...id,
  ...timestamps,
  version: serial('version_number'),
})
