import { Icons } from '@/components/icons'
import { ProviderConnectionForm } from '@/components/oauth-provider-form'
import { Button } from '@/components/ui/button'
import { withOptionalUser } from '@/lib/auth.server'
import { oauthProviders } from '@fuse/core/db/schema'
import type { SEOHandle } from '@nasa-gcn/remix-seo'
import { Separator } from '@radix-ui/react-separator'
import type { LoaderFunctionArgs } from '@remix-run/node'
import { redirect } from '@remix-run/node'
import { Link, useLoaderData } from '@remix-run/react'
import { z } from 'zod'

export const handle: SEOHandle = {
  getSitemapEntries: () => [
    { route: '/auth/sign-up', priority: 1.0 },
    { route: '/auth/login', priority: 1.0 },
  ],
}

export const actionSchema = z.enum(['sign-up', 'login'])

export const loader = (args: LoaderFunctionArgs) =>
  withOptionalUser(args, async ({ params }) => {
    const { action } = params
    const parsedAction = actionSchema.safeParse(action)

    if (parsedAction.success) return parsedAction.data
    return redirect('/auth/sign-up')
  })

export default function Auth() {
  const authAction = useLoaderData<typeof loader>()

  return (
    <>
      <div className='relative h-full w-full'>
        <div className='flex flex-col items-center justify-center h-full'>
          <h2 className='text-3xl font-extrabold pb-8 lg:pb-10 tracking-wide leading-[48px]'>
            {authAction === 'sign-up' ? 'Claim your account' : 'Welcome back!'}
          </h2>

          {authAction === 'sign-up' ? (
            <p className='mb-8 lg:mb-10 font-semibold text-lg text-muted-foreground text-center'>
              Register to start sending files securely now - <br /> 30-day
              expiration date, files up to 3 GB, for free.
            </p>
          ) : null}

          <div>
            <ul className='w-80 flex flex-col gap-y-6'>
              {oauthProviders.map((providerName) => (
                <li key={providerName}>
                  <ProviderConnectionForm
                    authAction={authAction}
                    providerName={providerName}
                  />
                </li>
              ))}
            </ul>

            <div className='flex items-center justify-center w-full py-6'>
              <Separator className='w-1/2 bg-gray-800 h-[1px]' />
              <span className='mx-3 text-sm'>OR</span>
              <Separator className='w-1/2 bg-gray-800 h-[1px]' />
            </div>
          </div>

          <Link
            to={
              authAction === 'login'
                ? '/auth/login/email'
                : '/auth/sign-up/email'
            }
          >
            <Button variant='outline' className='w-80 h-11'>
              <Icons.Mail className='mr-3 text-background fill-primary' />
              Continue with Email
            </Button>
          </Link>

          <div className='pt-6'>
            <span className='text-muted-foreground text-sm lg:text-xs font-normal leading-normal'>
              {authAction === 'sign-up'
                ? 'Already have an account?'
                : 'New to fuse.space?'}
            </span>{' '}
            <span className='text-blue-600 text-sm lg:text-xs font-normal leading-normal'>
              {authAction === 'sign-up' ? (
                <Link to='/auth/login'>Login here.</Link>
              ) : (
                <Link to='/auth/sign-up'>Create your account</Link>
              )}
            </span>
          </div>
        </div>

        <div className='absolute bottom-0 w-full'>
          <p className='text-center pb-5 text-sm lg:text-xs font-normal text-muted-foreground leading-normal'>
            By clicking sign up, you agree to our <br />
            <Link
              to='/terms'
              className='underline underline-offset-2 text-primary font-normal leading-normal'
            >
              Terms of Service
            </Link>{' '}
            and{' '}
            <Link
              to='/privacy'
              className='underline underline-offset-2 text-primary'
            >
              Privacy Policy
            </Link>
            .
          </p>
        </div>
      </div>
    </>
  )
}
